/**
 * Copyright Motili Inc., 2020 All Rights Reserved
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
    Checkbox,
    Heading1,
    Paragraph,
    RequiredFieldsDelimiter,
    SubText,
    TextArea,
    TextInput,
    Label,
    LabelWithRequired,
    DropDownSelect,
    LoadingSpinner,
} from 'motili-ui';
import PropTypes from 'prop-types';
import {
    initWorkOrderRequestForm,
    submitWorkOrderRequestForm,
} from 'common/services/WorkRequestFormService';
import logo from 'images/logo-blue.png';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from 'common/components/Alert';
import { isEmail } from 'common/utils/Validator';
import _ from 'lodash';
import classNames from 'classnames';
import Modal from 'common/components/modal/Modal';
import MotiliButtonContainer from 'common/components/buttons/MotiliButtonContainer';
import { FormElement, FormRow } from 'common/components/form/FormLayout';
import workRequestStyles from './WorkRequestFormContainer.module.scss';
import MotiliButton from '../../components-v2/buttons/MotiliButton';
import FormPhoneInput from '../../components/form/FormPhoneInput';
import { getTerminology } from '../../utils/terminology';

export default function WorkRequestFormContainer() {
    const params = useParams();
    const { clientId, uuid } = params;
    const [busy, setBusy] = useState(false);
    const [formConfig, setFormConfig] = useState();
    const [isMounted, setIsMounted] = useState(true);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

    /**
     * handle mount state
     */
    useEffect(() => {
        return () => {
            setIsMounted(false);
        };
    }, []);

    /**
     * Init form
     */
    useEffect(() => {
        (async () => {
            if (isMounted) {
                setBusy(true);
                await intForm();
                setBusy(false);
            }
        })();
    }, [isMounted]);

    const handleSubmitWorkOrderRequestForm = useCallback(
        async data => {
            if (isMounted) {
                Alert.close();
                setBusy(true);
                try {
                    await submitWorkOrderRequestForm(
                        {
                            ...data,
                            clientId: parseInt(clientId),
                        },
                        formConfig.accessToken
                    );

                    setSuccessfullySubmitted(true);
                } catch (error) {
                    if (error?.statusCode === 400 && error?.message) {
                        await intForm();
                        Alert.error(error?.message);
                    } else {
                        Alert.error(
                            'An error occurred submitting the work order request form, please try again.'
                        );
                    }
                }
                setBusy(false);
            }
        },
        [formConfig, isMounted]
    );

    const intForm = useCallback(async () => {
        if (isMounted) {
            try {
                const formConfig = await initWorkOrderRequestForm(
                    clientId,
                    uuid
                );
                setFormConfig(formConfig);
            } catch (err) {
                Alert.error('Cannot init form, please refresh page');
            }
        }
    }, [isMounted]);

    if (successfullySubmitted) {
        return (
            <SuccessPage
                handleSubmitAnotherOnClick={async () => {
                    await intForm();
                    setSuccessfullySubmitted(false);
                }}
                formConfig={formConfig}
            />
        );
    }

    return (
        <>
            {busy && <LoadingSpinner fullscreen />}
            {formConfig && (
                <WorkRequestForm
                    formConfig={formConfig}
                    clientId={parseInt(clientId)}
                    handleSubmitWorkOrderRequestForm={
                        handleSubmitWorkOrderRequestForm
                    }
                />
            )}
        </>
    );
}

/**
 * Success page that is shown after submission
 * @param handleSubmitAnotherOnClick
 * @param formConfig
 * @returns {*}
 * @constructor
 */
function SuccessPage({ handleSubmitAnotherOnClick, formConfig }) {
    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <img src={logo} alt='motili' style={styles.logo} />
                <Heading1>Success!</Heading1>
                <Paragraph>
                    Your work request has been submitted to a Motili
                    representative to review.
                </Paragraph>
                <Paragraph>
                    If any information is missing from your request, or any
                    other issues, a Motili representative will reach out to you
                    to discuss using information we have on file.
                </Paragraph>
                <Paragraph>
                    If you need to speak with us, please email us at{' '}
                    <a href={`mailto:${formConfig?.contactChannel?.email}`}>
                        {formConfig?.contactChannel?.email}
                    </a>{' '}
                    or call{' '}
                    <a href={`tel:${formConfig?.contactChannel?.phone}`}>
                        {formConfig?.contactChannel?.phone}
                    </a>{' '}
                    for assistance.
                </Paragraph>

                <div
                    style={{
                        alignContent: 'flex-end',
                        marginLeft: 'auto',
                        marginTop: '40px',
                    }}
                >
                    <MotiliButton
                        moStyle='primary'
                        onClick={handleSubmitAnotherOnClick}
                        text='Submit Another Work Order'
                    />
                </div>
            </div>
        </div>
    );
}
SuccessPage.propTypes = {
    handleSubmitAnotherOnClick: PropTypes.func.isRequired,
    formConfig: PropTypes.shape({
        contactChannel: PropTypes.shape({
            email: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
        padding: '2.5em 0',
    },
    innerContainer: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '70%',
        padding: '6em',
        alignSelf: 'center',
        margin: '0px 10px',
        minHeight: '50vh',
        boxShadow:
            '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
    },
    logo: {
        width: 70,
    },
};

function Horizontal(props) {
    return (
        <div
            style={{
                ...props.style,
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            {_.isArray(props.children)
                ? _.map(props.children, (ch, index) => {
                      if (ch) {
                          return (
                              <div
                                  key={index}
                                  style={{
                                      width: '100%',
                                      paddingLeft:
                                          index > 0 ? '15px' : undefined,
                                  }}
                              >
                                  {ch}
                              </div>
                          );
                      }
                      return ch;
                  })
                : props.children}
        </div>
    );
}
Horizontal.propTypes = {
    style: PropTypes.shape({
        width: PropTypes.string,
    }),
    children: PropTypes.node,
};

function Vertical(props) {
    return (
        <div
            style={{
                ...props.style,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {_.isArray(props.children)
                ? _.map(props.children, (ch, index) => {
                      if (ch) {
                          return (
                              <div
                                  key={index}
                                  style={{
                                      width: '100%',
                                      paddingTop:
                                          index > 0 ? '15px' : undefined,
                                  }}
                              >
                                  {ch}
                              </div>
                          );
                      }
                      return ch;
                  })
                : props.children}
        </div>
    );
}
Vertical.propTypes = {
    style: PropTypes.shape({
        width: PropTypes.string,
    }),
    children: PropTypes.node,
};

function FormSection(props) {
    return (
        <Vertical
            style={{
                ...props.style,
                marginBottom: props.style?.marginBottom
                    ? props.style?.marginBottom
                    : '45px',
            }}
        >
            {/* Form section label */}
            {props.label && (
                <Label
                    style={{
                        fontWeight: 'bold',
                    }}
                    label={props.label}
                />
            )}

            {/* Inputs Form */}
            <Vertical style={{ marginTop: '-10px' }}>{props.children}</Vertical>
        </Vertical>
    );
}
FormSection.propTypes = {
    style: PropTypes.shape({
        marginBottom: PropTypes.string,
    }),
    label: PropTypes.string,
    children: PropTypes.node,
};

/**
 * Define validation schema
 */
const validationSchema = formConfig =>
    yup.object().shape({
        agentBilledId: yup.string().when('billedAgent', {
            is: true,
            then: yup.string().trim().required(),
        }),
        address1: yup.string().trim().required(),
        address2: yup.string().trim(),
        city: yup.string().trim(),
        zipcode:
            formConfig.operationInCountry === 'US'
                ? yup
                      .string()
                      .trim()
                      .matches(/^\d{5}(?:[-\s]\d{4})?$/, {
                          excludeEmptyString: true,
                          message: 'Please enter a valid zipcode',
                      })
                : undefined,
        occupied: yup.bool().required(),
        propertyTypeId: yup.string().required(),
        priorityId: yup.string().required(),
        tradeType: yup.string().required(),
        lockboxCode: yup.string().trim(),
        gateCode: yup.string().trim(),
        descriptionOfProblem: yup.string().trim().required(),
        contactTypeId: yup.string().trim().required(),
        contactName: yup.string().trim().required(),
    });

function WorkRequestForm(props) {
    const { formConfig, handleSubmitWorkOrderRequestForm } = props;
    const [contactMobileCountry, setContactMobileCountry] = useState(
        formConfig?.operationInCountry || 'US'
    );
    const [contactMobile, setContactMobile] = useState('');
    const [contactLandlineCountry, setContactLandlineCountry] = useState(
        formConfig?.operationInCountry || 'US'
    );
    const [contactLandline, setContactLandline] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [isMounted, setIsMounted] = useState(true);
    const [contactEmailValid, setContactEmailValid] = useState(true);
    const [contactMobileValid, setContactMobileValid] = useState(true);
    const [contactLandlineValid, setContactLandlineValid] = useState(true);
    const [showSubmitConfirmModal, setShowSubmitConfirmModal] = useState(false);

    const unMaskPhoneNumber = useCallback(phone => {
        return (
            phone
                ?.trim()
                .replace(/(\()|(_)|(-)|( )|(\))/g, '')
                .trim() || ''
        );
    }, []);
    const tradeTypesOptions = useMemo(() => {
        const tradeTypesOptions = [];
        _.forEach(formConfig?.tradeTypes, item => {
            _.forEach(item.tradeSubTypes, subItem => {
                tradeTypesOptions.push({
                    label: `${item.display} - ${subItem.display}`,
                    value: `${item.id}-${subItem.id}`,
                });
            });
        });
        return tradeTypesOptions;
    }, [formConfig]);
    const isContactInfoValid = useMemo(() => {
        if (
            contactEmail?.trim() === '' &&
            unMaskPhoneNumber(contactMobile) === '' &&
            unMaskPhoneNumber(contactLandline) === ''
        ) {
            return false;
        }

        if (!contactEmailValid) {
            return false;
        }
        if (contactMobileValid !== undefined && !contactMobileValid) {
            return false;
        }
        if (contactLandlineValid !== undefined && !contactLandlineValid) {
            return false;
        }

        return true;
    }, [
        contactEmail,
        contactMobile,
        contactLandline,
        contactEmailValid,
        contactMobileValid,
        contactLandlineValid,
    ]);

    /**
     * handle mounted state
     */
    useEffect(() => {
        return () => {
            setIsMounted(false);
        };
    }, []);

    /**
     * Validate contactEmail
     */
    useEffect(() => {
        if (isMounted) {
            const email = contactEmail?.trim() || '';
            if (email === '') {
                setContactEmailValid(true);
            } else {
                setContactEmailValid(isEmail(email));
            }
        }
    }, [contactEmail, isMounted]);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        watch,
        setValue,
        control,
    } = useForm({
        resolver: yupResolver(validationSchema(formConfig)),
        mode: 'onChange',
    });
    const watchBilledAgent = watch('billedAgent', false);
    const agentBilledId = watch('agentBilledId', undefined);
    const watchPriorityId = watch('priorityId', false);
    const state = watch('state', undefined);
    const occupied = watch('occupied', undefined);
    const propertyTypeId = watch('propertyTypeId', undefined);
    const priorityId = watch('priorityId', undefined);
    const tradeType = watch('tradeType', undefined);
    const contactTypeId = watch('contactTypeId', undefined);
    const sortAgents = (a, b) => {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    const agentOptions = _.map(formConfig?.agents.sort(sortAgents), agent => ({
        value: agent.id,
        label: `${agent.firstName} ${agent.lastName} - ${agent.company}`,
    }));

    const stateOptions = _.map(formConfig?.states, item => ({
        value: item.id,
        label: item.display,
    }));

    const occupiedOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    const propertyTypeOptions = _.map(formConfig?.propertyTypes, item => ({
        value: item.id,
        label: item.display,
    }));

    const priorityTypeOptions = _.map(formConfig?.priorityTypes, item => ({
        value: item.id,
        label: item.display,
    }));

    const contactTypeOptions = _.map(formConfig?.contactTypes, item => ({
        value: item.id,
        label: item.display,
    }));

    const findAgentInfo = agentBilledId => {
        const agentInfo = formConfig.agents.find(
            agent => agent.id === agentBilledId
        );
        setContactEmail(agentInfo.email);
        setContactLandline(agentInfo.landline);
        setContactMobile(agentInfo.mobile);
        setValue(
            'contactName',
            `${agentInfo.firstName} ${agentInfo.lastName}`,
            { shouldValidate: true }
        );
    };

    useEffect(() => {
        if (contactTypeId === 'AGENT') {
            findAgentInfo(agentBilledId);
        }
    }, [contactTypeId, agentBilledId]);

    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <img src={logo} alt='motili' style={styles.logo} />
                <Heading1>Work Order Request</Heading1>
                <RequiredFieldsDelimiter />
                <div
                    style={{
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                >
                    {formConfig && (
                        <Paragraph>
                            If you experience any issues with the form below or
                            have any questions, please email us at{' '}
                            <a
                                href={`mailto:${formConfig.contactChannel.email}`}
                            >
                                {formConfig.contactChannel.email}
                            </a>{' '}
                            or call{' '}
                            <a href={`tel:${formConfig.contactChannel.phone}`}>
                                {formConfig.contactChannel.phone}
                            </a>{' '}
                            for assistance.
                        </Paragraph>
                    )}
                </div>
                <FormSection>
                    <Horizontal>
                        <TextInput
                            name='clientName'
                            value={
                                formConfig?.clientName
                                    ? formConfig.clientName
                                    : ''
                            }
                            onChange={() => undefined}
                            required
                            label='Client Name'
                            disabled
                            style={{
                                width: '100%',
                            }}
                        />
                        {formConfig?.agentBillingEnabled &&
                            formConfig?.agents?.length > 0 && (
                                <Vertical>
                                    <Controller
                                        name='agentBilledId'
                                        control={control}
                                        render={({ field }) => (
                                            <DropDownSelect
                                                {...field}
                                                value={agentOptions.find(
                                                    option =>
                                                        option.value ===
                                                        agentBilledId
                                                )}
                                                onChange={({ value }) =>
                                                    field.onChange(value)
                                                }
                                                name='agentBilledId'
                                                required={watchBilledAgent}
                                                label='Agent Information'
                                                options={agentOptions}
                                                placeholder='Make a Selection'
                                                className={classNames({
                                                    [workRequestStyles.dropdown]: true,
                                                    [workRequestStyles.notSelected]:
                                                        !agentBilledId &&
                                                        watchBilledAgent,
                                                })}
                                            />
                                        )}
                                    />
                                    <Checkbox
                                        name='billedAgent'
                                        register={register('billedAgent')}
                                        onChange={
                                            register('billedAgent').onChange
                                        }
                                        id='billedAgent'
                                        label='This work is billed to Agent above'
                                    />
                                </Vertical>
                            )}
                    </Horizontal>
                </FormSection>
                {/* Property Information */}
                <FormSection label='PROPERTY DETAILS'>
                    <Horizontal>
                        <TextInput
                            label='Property Address'
                            required
                            name='address1'
                            style={{
                                width: '100%',
                            }}
                            placeholder='Street Address'
                            register={register('address1')}
                            onChange={register('address1').onChange}
                        />
                        <div style={{ marginTop: '10px' }}>
                            <TextInput
                                name='address2'
                                style={{
                                    width: '100%',
                                }}
                                placeholder='Street Address2'
                                register={register('address2')}
                                onChange={register('address2').onChange}
                            />
                        </div>
                    </Horizontal>
                    <Horizontal>
                        <TextInput
                            name='city'
                            style={{
                                width: '100%',
                            }}
                            placeholder={getTerminology(
                                'City',
                                formConfig.operationInCountry
                            )}
                            register={register('city')}
                            onChange={register('city').onChange}
                        />
                        <Horizontal>
                            <Controller
                                name='state'
                                control={control}
                                render={({ field }) => (
                                    <DropDownSelect
                                        {...field}
                                        value={stateOptions.find(
                                            option => option.value === state
                                        )}
                                        onChange={({ value }) =>
                                            field.onChange(value)
                                        }
                                        label=' '
                                        options={stateOptions}
                                        placeholder={getTerminology(
                                            'State',
                                            formConfig.operationInCountry
                                        )}
                                        className={classNames({
                                            [workRequestStyles.dropdown]: true,
                                            [workRequestStyles.notSelected]:
                                                !state,
                                        })}
                                    />
                                )}
                            />

                            <TextInput
                                name='zipcode'
                                style={{
                                    width: '100%',
                                }}
                                placeholder={getTerminology(
                                    'Zip Code',
                                    formConfig.operationInCountry
                                )}
                                register={register('zipcode')}
                                onChange={register('zipcode').onChange}
                                errorMessage={errors?.zipcode?.message}
                                error={!!errors?.zipcode}
                            />
                        </Horizontal>
                    </Horizontal>
                    <Horizontal>
                        <Controller
                            name='occupied'
                            control={control}
                            render={({ field }) => (
                                <DropDownSelect
                                    value={occupiedOptions.find(
                                        option => option.value === occupied
                                    )}
                                    onChange={({ value }) =>
                                        field.onChange(value)
                                    }
                                    label='Is the property occupied?'
                                    required
                                    options={occupiedOptions}
                                    register={register}
                                    placeholder='Make a Selection'
                                    className={classNames({
                                        [workRequestStyles.dropdown]: true,
                                        [workRequestStyles.notSelected]:
                                            !occupied,
                                    })}
                                />
                            )}
                        />
                        <Controller
                            name='propertyTypeId'
                            control={control}
                            render={({ field }) => (
                                <DropDownSelect
                                    value={propertyTypeOptions.find(
                                        option =>
                                            option.value === propertyTypeId
                                    )}
                                    onChange={({ value }) =>
                                        field.onChange(value)
                                    }
                                    label='What kind of property?'
                                    required
                                    options={propertyTypeOptions}
                                    placeholder='Make a Selection'
                                    className={classNames({
                                        [workRequestStyles.dropdown]: true,
                                        [workRequestStyles.notSelected]:
                                            !propertyTypeId,
                                    })}
                                />
                            )}
                        />
                    </Horizontal>
                    <Horizontal>
                        <Horizontal>
                            <TextInput
                                label='Lock Box Code'
                                name='lockboxCode'
                                style={{
                                    width: '100%',
                                }}
                                register={register('lockboxCode')}
                                onChange={register('lockboxCode').onChange}
                            />
                            <TextInput
                                label='Gate Code'
                                name='gateCode'
                                style={{
                                    width: '100%',
                                }}
                                register={register('gateCode')}
                                onChange={register('gateCode').onChange}
                            />
                        </Horizontal>
                        <Horizontal />
                    </Horizontal>
                </FormSection>
                {/* Work Information */}
                <FormSection label='WORK INFORMATION'>
                    <Horizontal>
                        <div>
                            <Controller
                                name='priorityId'
                                control={control}
                                render={({ field }) => (
                                    <DropDownSelect
                                        value={priorityTypeOptions.find(
                                            option =>
                                                option.value === priorityId
                                        )}
                                        onChange={({ value }) =>
                                            field.onChange(value)
                                        }
                                        label='What priority would you classify this work?'
                                        required
                                        options={priorityTypeOptions}
                                        placeholder='Make a Selection'
                                        className={classNames({
                                            [workRequestStyles.dropdown]: true,
                                            [workRequestStyles.notSelected]:
                                                !priorityId,
                                        })}
                                    />
                                )}
                            />
                            {watchPriorityId === 'EMERGENCY' && (
                                <SubText
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    If your work completed as an Emergency, a
                                    fee of 1.5 times the standard rate is
                                    applied to billing.
                                </SubText>
                            )}
                        </div>
                        <Controller
                            name='tradeType'
                            control={control}
                            render={({ field }) => (
                                <DropDownSelect
                                    value={tradeTypesOptions.find(
                                        option => option.value === tradeType
                                    )}
                                    onChange={({ value }) =>
                                        field.onChange(value)
                                    }
                                    label='Trade Type'
                                    required
                                    options={tradeTypesOptions}
                                    placeholder='Make a Selection'
                                    className={classNames({
                                        [workRequestStyles.dropdown]: true,
                                        [workRequestStyles.notSelected]:
                                            !tradeType,
                                    })}
                                />
                            )}
                        />
                    </Horizontal>
                    <Horizontal>
                        <TextInput
                            label='PO Number'
                            name='poNumber'
                            style={{
                                width: '100%',
                            }}
                            register={register('poNumber')}
                            onChange={register('poNumber').onChange}
                        />
                        <TextInput
                            label='Reference Number'
                            name='ref1'
                            style={{
                                width: '100%',
                            }}
                            register={register('ref1')}
                            onChange={register('ref1').onChange}
                        />
                    </Horizontal>
                    <TextArea
                        label='Please describe the work needed and any additional details needed to access the property'
                        required
                        name='descriptionOfProblem'
                        register={register('descriptionOfProblem')}
                        onChange={register('descriptionOfProblem').onChange}
                        style={{
                            width: '100%',
                        }}
                    />
                </FormSection>

                <FormSection label='PROPERTY CONTACT INFORMATION'>
                    <Horizontal>
                        <Vertical>
                            <Controller
                                name='contactTypeId'
                                control={control}
                                render={({ field }) => (
                                    <DropDownSelect
                                        value={contactTypeOptions.find(
                                            option =>
                                                option.value === contactTypeId
                                        )}
                                        onChange={({ value }) =>
                                            field.onChange(value)
                                        }
                                        label='Contact Type'
                                        required
                                        options={
                                            !agentBilledId
                                                ? contactTypeOptions
                                                : [
                                                      {
                                                          label: 'Agent',
                                                          value: 'AGENT',
                                                      },
                                                      ...contactTypeOptions,
                                                  ]
                                        }
                                        placeholder='Make a Selection'
                                        className={classNames({
                                            [workRequestStyles.dropdown]: true,
                                            [workRequestStyles.notSelected]:
                                                !contactTypeId,
                                        })}
                                    />
                                )}
                            />
                            <TextInput
                                label='Contact Name'
                                required
                                name='contactName'
                                style={{
                                    width: '100%',
                                }}
                                register={register('contactName')}
                                onChange={register('contactName').onChange}
                            />
                        </Vertical>
                        <Vertical>
                            <LabelWithRequired
                                label='Contact Information (At least one is required)'
                                required
                            />
                            <Vertical
                                style={{
                                    border: '1px',
                                    borderStyle: 'solid',
                                    borderColor: '#E8E8E8',
                                    padding: '20px',
                                    marginTop: '-16px',
                                }}
                            >
                                <TextInput
                                    onChange={e => {
                                        setContactEmail(e.target.value || '');
                                    }}
                                    value={contactEmail}
                                    errorMessage='Please enter a valid Email'
                                    error={!contactEmailValid}
                                    label='Contact Email Address'
                                    name='contactEmail'
                                    style={{
                                        width: '100%',
                                    }}
                                    type='email'
                                />
                                <FormPhoneInput
                                    value={contactMobile}
                                    phoneCountry={contactMobileCountry}
                                    onChange={e => {
                                        setContactMobile(e.value || '');
                                        setContactMobileValid(e.isValid);
                                        setContactMobileCountry(e.phoneCountry);
                                    }}
                                    validation={{
                                        state: contactMobileValid
                                            ? 'success'
                                            : 'error',
                                        message:
                                            'Please enter a valid Phone Number (Mobile)',
                                    }}
                                    isMobile
                                    label='Contact Phone Number (Mobile)'
                                    name='contactMobile'
                                    mask='phone'
                                    style={{
                                        width: '100%',
                                    }}
                                />
                                <FormPhoneInput
                                    phoneCountry={contactLandlineCountry}
                                    value={contactLandline}
                                    onChange={e => {
                                        setContactLandline(e.value || '');
                                        setContactLandlineValid(e.isValid);
                                        setContactLandlineCountry(
                                            e.phoneCountry
                                        );
                                    }}
                                    validation={{
                                        state: contactLandlineValid
                                            ? 'success'
                                            : 'error',
                                        message:
                                            'Please enter a valid Phone Number (Landline)',
                                    }}
                                    label='Contact Phone Number (Landline)'
                                    name='contactLandline'
                                    mask='phone'
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Vertical>
                            {showSubmitConfirmModal && (
                                <Modal
                                    style={{ width: '40%' }}
                                    contentLabel='submit-confirm-modal'
                                    onCloseClick={() =>
                                        setShowSubmitConfirmModal(false)
                                    }
                                    header='Emergency Fee'
                                    showClose
                                    footer={
                                        <MotiliButtonContainer>
                                            <MotiliButton
                                                text='Cancel'
                                                onClick={() =>
                                                    setShowSubmitConfirmModal(
                                                        false
                                                    )
                                                }
                                                moStyle='normal'
                                            />
                                            <MotiliButton
                                                text='Submit'
                                                onClick={handleSubmit(data => {
                                                    const tradeTypeData =
                                                        data.tradeType.split(
                                                            '-'
                                                        );
                                                    handleSubmitWorkOrderRequestForm(
                                                        {
                                                            ...data,
                                                            contactTypeId:
                                                                contactTypeId ===
                                                                'AGENT'
                                                                    ? 'CT_OTHER'
                                                                    : contactTypeId,
                                                            descriptionOfProblem:
                                                                formConfig?.instructions
                                                                    ? `${data.descriptionOfProblem}\n\n ${formConfig.instructions}`
                                                                    : data.descriptionOfProblem,
                                                            tradeTypeId:
                                                                tradeTypeData[0],
                                                            tradeSubTypeId:
                                                                tradeTypeData[1],
                                                            contactEmail,
                                                            contactMobile,
                                                            contactLandline,
                                                            contactMobileCountry,
                                                            contactLandlineCountry,
                                                        }
                                                    );
                                                })}
                                            />
                                        </MotiliButtonContainer>
                                    }
                                >
                                    <FormRow>
                                        <FormElement>
                                            Are you sure you want to submit this
                                            work as an Emergency? If work is
                                            completed as an Emergency, a fee of
                                            1.5 times the standard rate is
                                            applied to billing.
                                        </FormElement>
                                    </FormRow>
                                </Modal>
                            )}
                            <MotiliButton
                                moStyle='primary'
                                text='Submit'
                                onClick={handleSubmit(data => {
                                    if (priorityId === 'EMERGENCY') {
                                        setShowSubmitConfirmModal(true);
                                    } else {
                                        const tradeTypeData =
                                            data.tradeType.split('-');
                                        handleSubmitWorkOrderRequestForm({
                                            ...data,
                                            contactTypeId:
                                                contactTypeId === 'AGENT'
                                                    ? 'CT_OTHER'
                                                    : contactTypeId,
                                            descriptionOfProblem:
                                                formConfig?.instructions
                                                    ? `${data.descriptionOfProblem}\n\n ${formConfig.instructions}`
                                                    : data.descriptionOfProblem,
                                            tradeTypeId: tradeTypeData[0],
                                            tradeSubTypeId: tradeTypeData[1],
                                            contactEmail,
                                            contactMobile,
                                            contactLandline,
                                            contactMobileCountry,
                                            contactLandlineCountry,
                                        });
                                    }
                                })}
                                disabled={!isValid || !isContactInfoValid}
                            />
                        </Vertical>
                    </Horizontal>
                </FormSection>
            </div>
        </div>
    );
}
WorkRequestForm.propTypes = {
    formConfig: PropTypes.shape({
        clientName: PropTypes.string,
        agentBillingEnabled: PropTypes.bool,
        agents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                company: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                landline: PropTypes.string.isRequired,
                mobile: PropTypes.string.isRequired,
            })
        ),
        states: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                display: PropTypes.string.isRequired,
            })
        ),
        propertyTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                display: PropTypes.string.isRequired,
            })
        ),
        priorityTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                display: PropTypes.string.isRequired,
            })
        ),
        contactTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                display: PropTypes.string.isRequired,
            })
        ),
        tradeTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                display: PropTypes.string.isRequired,
                tradeSubTypes: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        display: PropTypes.string.isRequired,
                    })
                ).isRequired,
            })
        ),
        instructions: PropTypes.string,
        contactChannel: PropTypes.shape({
            email: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
        }),
        operationInCountry: PropTypes.string,
    }).isRequired,
    handleSubmitWorkOrderRequestForm: PropTypes.func.isRequired,
};
