/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import React from 'react';
import styled from 'styled-components';

import {
    ButtonPrimary,
    ButtonSecondary,
    ButtonProps,
    LoadingSpinner,
} from 'motili-ui';
import { lighten, darken } from 'polished';
import Config from '../../../Config';

interface MotiliButtonProps extends Partial<ButtonProps> {
    onClick: (e: any) => any;
    text?: string | JSX.Element;
    disabled?: boolean;
    block?: boolean;
    type?: string;
    id?: string;
    active?: boolean;
    size?: 'sm' | 'md' | 'lg';
    moStyle?: 'normal' | 'primary';
    dialog?: boolean;
    fullPage?: boolean;
    busy?: boolean;
    style?: React.CSSProperties;
    color?: string;
    className?: string;
    dataTestId?: string;
}

const MotiliButton = ({
    size = 'md',
    moStyle = 'primary',
    busy = false,
    color,
    dataTestId = 'motili-ui-button',
    ...props
}: MotiliButtonProps) => {
    return (
        <>
            {moStyle === 'normal' && (
                <StyledSecondaryButton
                    disabled={props.disabled || busy}
                    onClick={props.onClick}
                    buttonStyle={{
                        borderColor: '#CCC',
                        minWidth: 160,
                        height: 35,
                        ...props.style,
                    }}
                    color={color}
                    dataTestId={dataTestId}
                >
                    {busy ? (
                        <LoadingSpinner
                            color={styles.busySpinner[moStyle].color}
                            size='small'
                        />
                    ) : (
                        <span>{props.text}</span>
                    )}
                </StyledSecondaryButton>
            )}
            {moStyle === 'primary' && (
                <StyledPrimaryButton
                    disabled={props.disabled || busy}
                    onClick={props.onClick}
                    buttonStyle={{
                        minWidth: 160,
                        height: 35,
                        ...props.style,
                    }}
                    color={color}
                    dataTestId={dataTestId}
                >
                    {busy ? (
                        <div style={{ maxHeight: 35 }}>
                            <LoadingSpinner
                                color={
                                    color
                                        ? lighten(0.25, color)
                                        : styles.busySpinner[moStyle].color
                                }
                                size='small'
                            />
                        </div>
                    ) : (
                        <span>{props.text}</span>
                    )}
                </StyledPrimaryButton>
            )}
        </>
    );
};

const StyledPrimaryButton = styled(ButtonPrimary)<MotiliButtonProps>`
    background-color: ${props => props.color || Config.color.motiliLightBlue};
    &:hover {
        background-color: ${props =>
            darken(0.05, props.color || Config.color.motiliLightBlue)};
    }
`;

const StyledSecondaryButton = styled(ButtonSecondary)<MotiliButtonProps>`
    background-color: ${props => props.color || 'white'};
    &:hover {
        background-color: ${props => darken(0.1, props.color || 'white')};
    }
`;

const styles = {
    busySpinner: {
        normal: {
            color: Config.color.motiliLightBlue,
        },
        primary: {
            color: Config.color.white,
        },
    },
};

export default MotiliButton;
