/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import { Component } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars

import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import * as Validator from '../../utils/Validator';
import FormTextInput from './FormTextInput';
import * as TwilioService from '../../services/TwilioService';
import FormSelect from './FormSelect';
import ReduxStore from '../../stores/redux/store';
import styles from './FormPhoneInput.module.scss';

export default class FormPhoneInput extends Component {
    static propTypes = {
        ...FormTextInput.propTypes,
        isMobile: PropTypes.bool,
        phoneCountry: PropTypes.string,
        required: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        ...FormTextInput.defaultProps,
        isMobile: false,
        phoneCountry: 'US',
    };

    static defaultValidation = {
        message: 'Invalid',
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.value !== prevProps.value ||
            this.props.phoneCountry !== prevProps.phoneCountry
        ) {
            this.setState(
                {
                    isValid: null,
                },
                () => {
                    this.validatePhone(
                        this.props.value,
                        this.props.phoneCountry
                    );
                }
            );
        }

        if (this.props.isMobile !== prevProps.isMobile) {
            this.setState(
                {
                    isValid: null,
                },
                () => {
                    this.validatePhone(
                        this.props.value,
                        this.props.phoneCountry
                    );
                }
            );
        }
    }

    constructor(props) {
        super(props);
        this.countryCodeOptions =
            ReduxStore.getState().metaStore.metaData.country.map(c => ({
                id: c.id,
                display: `${c.description} (${c.countryCode})`,
            }));
        this.state = {
            busy: false,
            isValid: this.props.value ? true : null,
        };
    }

    componentDidMount() {
        this.validatePhone(this.props.value, this.props.phoneCountry);
    }

    getValue(value) {
        return this.props.saveUnmasked ? this.unMask(value) : value;
    }

    unMask(value) {
        return value.replace(/\D/g, '');
    }

    validatePhone(value, phoneCountry) {
        if (value) {
            const phoneValidator = this.props.saveUnmasked
                ? Validator.isUnformattedPhone
                : Validator.isPhone;
            if (phoneValidator(value, phoneCountry)) {
                const _service = this.props.isMobile
                    ? TwilioService.validateMobile
                    : TwilioService.validatePhone;
                this.setState({ busy: true }, () =>
                    _service(value, phoneCountry)
                        .then(valid =>
                            this.setState(
                                { isValid: valid, busy: false },
                                () => {
                                    if (this.props.onChange) {
                                        this.props.onChange({
                                            value: this.getValue(value),
                                            isValid: valid,
                                            phoneCountry,
                                        });
                                    }
                                }
                            )
                        )
                        .catch(() =>
                            this.setState(
                                { isValid: false, busy: false },
                                () => {
                                    if (this.props.onChange) {
                                        this.props.onChange({
                                            value: this.getValue(value),
                                            isValid: false,
                                            phoneCountry,
                                        });
                                    }
                                }
                            )
                        )
                );
            }
        }
    }

    onBlur(...args) {
        const value = args[0].target.value;

        if (value && !Validator.isPhone(value, this.props.phoneCountry)) {
            this.setState({ isValid: false }, () => {
                if (this.props.onChange) {
                    this.props.onChange({
                        value: this.getValue(value),
                        isValid: false,
                        phoneCountry: this.props.phoneCountry,
                    });
                }
            });
        } else {
            this.validatePhone(value, this.props.phoneCountry);
        }
    }

    onChange = (...args) => {
        const value = args[0].target.value;
        if (this.props.onChange) {
            this.props.onChange({
                value: this.getValue(value),
                phoneCountry: this.props.phoneCountry,
            });
        }
    };

    onChangeCountryCode = (...args) => {
        const country = args[0].id;
        if (this.props.onChange) {
            this.props.onChange({
                value: this.props.value,
                phoneCountry: country,
            });
        }
    };

    getValidationState() {
        if (this.state.isValid === true) {
            return { state: 'success' };
        }
        if (this.state.isValid === false) {
            let message = this.props.isMobile
                ? 'Invalid mobile format'
                : 'Invalid phone format';
            if (this.props.validation.message) {
                message = this.props.validation.message;
            }
            return { state: 'error', message };
        }
        return undefined;
    }

    render() {
        return (
            <div className={this.props.className}>
                {this.props.label && (
                    <ControlLabel
                        style={this.props.labelStyle || { fontSize: 12 }}
                    >
                        {this.props.label}
                        {this.props.required && (
                            <span className={styles.requiredStar}>*</span>
                        )}
                    </ControlLabel>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <div style={{ width: '50%' }}>
                        <FormSelect
                            options={this.countryCodeOptions}
                            value={this.props.phoneCountry}
                            onChange={this.onChangeCountryCode}
                            disabled={this.props.disabled || this.state.busy}
                        />
                    </div>
                    <div style={{ width: '50%', marginLeft: '5px' }}>
                        <FormTextInput
                            validation={{
                                ...this.getValidationState(),
                            }}
                            mask='phone'
                            onChange={this.onChange}
                            onBlur={(...args) => this.onBlur(...args)}
                            value={this.props.value}
                            disabled={this.props.disabled || this.state.busy}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
