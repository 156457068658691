import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

export type ValidationState = 'success' | 'error' | 'warning' | null;

export interface Validation {
    state: ValidationState;
    message?: string;
}

interface FormValidationDisplayProps {
    validation: Validation;
    style?: Object;
    hideIcon?: boolean;
}

const FormValidationDisplay = ({
    validation,
    style,
    hideIcon,
}: FormValidationDisplayProps) => {
    const getValidationStateIcon = (validationState: ValidationState) => {
        if (hideIcon) return '';
        switch (validationState) {
            case 'success':
                return 'fa fa-check-circle';
            case 'error':
                return 'fa fa-times';
            default:
                return '';
        }
    };
    const getValidationMessage = (
        validationState: ValidationState,
        validationMessage: string | undefined
    ) => {
        switch (validationState) {
            case 'error':
                return validationMessage || 'Invalid';
            case 'warning':
                return validationMessage || 'Warning';
            default:
                return '';
        }
    };
    return (
        <>
            <FormControl.Feedback style={style}>
                <i className={getValidationStateIcon(validation.state)} />
            </FormControl.Feedback>
            <HelpBlock>
                {getValidationMessage(validation.state, validation.message)}
            </HelpBlock>
        </>
    );
};

export default FormValidationDisplay;
