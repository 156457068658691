/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import * as ApiService from './ApiService';
import * as Validator from '../utils/Validator';
import * as utils from '../utils/Utils';

const baseUrl = 'documents';

export function find(data) {
    return ApiService.post(true, `${baseUrl}/search`, data);
}

export function count(data) {
    return ApiService.post(true, `${baseUrl}/search/count`, data);
}

export function getDocumentByJobNumber(documentType, jobNumber, filter) {
    return ApiService.get(true, `${documentType}/byJobNumber/${jobNumber}`, {
        filter,
    });
}

export function selectPreferredContractor(propertyActiveProfile, client) {
    return {
        preferredContractor1: propertyActiveProfile
            ? propertyActiveProfile.primaryPreferredContractor
            : undefined,
        preferredContractor2: propertyActiveProfile
            ? propertyActiveProfile.secondaryPreferredContractor
            : undefined,
        preferredContractor3: propertyActiveProfile
            ? propertyActiveProfile.preferredContractor3
            : undefined,
        preferredContractor4: propertyActiveProfile
            ? propertyActiveProfile.preferredContractor4
            : undefined,
    };

    // Disable proferred contractor from client for now.
    // We don't merge preferred contractor on property level with client preferred contractor level.
    // If property primaryPreferredContractor were set
    // then use from the property level otherwise use the client value.
    /*
    if (
        propertyActiveProfile &&
        propertyActiveProfile.primaryPreferredContractor
    ) {
        return {
            preferredContractor1:
                propertyActiveProfile.primaryPreferredContractor,
            preferredContractor2:
                propertyActiveProfile.secondaryPreferredContractor,
            preferredContractor3: propertyActiveProfile.preferredContractor3,
            preferredContractor4: propertyActiveProfile.preferredContractor4,
        };
    }

    return {
        preferredContractor1: client ? client.preferredContractor1 : undefined,
        preferredContractor2: client ? client.preferredContractor2 : undefined,
        preferredContractor3: client ? client.preferredContractor3 : undefined,
        preferredContractor4: client ? client.preferredContractor4 : undefined,
    };
    */
}

/**
 * synchronously validates a work order contact object
 * @param {object} contact
 * @returns {boolean}
 */
export function isValidContact(contact) {
    const { typeId, name, email, mobile, phone, validMobile, validPhone } =
        contact;
    if (!typeId) {
        return false;
    }
    if (!name || !Validator.isMax80Characters(name)) return false;
    if (!email && !mobile && !phone) {
        return false;
    }
    if (email && !Validator.isEmail(email)) {
        return false;
    }

    if (
        mobile &&
        !(
            (Validator.isPhone(mobile) ||
                Validator.isUnformattedPhone(mobile)) &&
            validMobile
        )
    ) {
        return false;
    }
    if (
        phone &&
        !(
            (Validator.isPhone(phone) || Validator.isUnformattedPhone(phone)) &&
            validPhone
        )
    ) {
        return false;
    }
    return true;
}

/**
 * getDocumentSignedUrl
 * @param {*} Key - document key
 */
export function getDocumentSignedUrl(Key) {
    return ApiService.post(true, `${baseUrl}/getReportSignedUrl`, { Key });
}

export function getRegionalContacts(query) {
    return ApiService.get(true, `${baseUrl}/regionalContacts`, query);
}

export async function updatePaymentResponsibility(
    documentId,
    documentType,
    homeownerResponsibility
) {
    const query = {
        documentType,
        ...((homeownerResponsibility || homeownerResponsibility === 0) && {
            homeownerResponsibility,
        }),
    };

    return ApiService.patch(
        true,
        `${baseUrl}/${documentId}/updatePaymentResponsibility`,
        query
    );
}

export function initDocumentContact() {
    return {
        uuid: utils.generateRandomUUID(),
        typeId: '',
        name: '',
        email: '',
        mobile: '',
        mobileCountry: 'US',
        phone: '',
        phoneCountry: 'US',
        notifications: [],
        id: null,
        contactPreferenceId: '',
        notes: '',
        validMobile: false,
        validPhone: false,
        primaryContact: false,
    };
}
