/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import { forwardRef } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputMask from 'react-input-mask';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import classNames from 'classnames';
import FormValidationDisplay from 'common/components/form/FormValidationDisplay';
import styles from './FormTextInput.module.scss';
/**
 * FormTextInput - Standard Text Input for use within a form
 * @param {Object} props
 * @returns {JSX<Object>}
 */

/* // @ts-ignore */

const FormTextInput = forwardRef(function FormTextInput(props, ref) {
    return (
        <FormGroup
            style={props.style}
            className={props.className}
            controlId={props.controlId || props.name}
            bsSize={props.bsSize}
            validationState={props.validation.state}
        >
            {props.label && (
                <ControlLabel style={props.labelStyle || { fontSize: 12 }}>
                    {props.label}
                </ControlLabel>
            )}
            {props.required && <span className={styles.requiredStar}>*</span>}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <InputGroupConditionalWrapper {...props}>
                {props.inputGroupBefore}
                {!props.mask && (
                    <FormControl
                        inputRef={ref}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        onKeyPress={props.onKeyPress}
                        onBlur={props.onBlur}
                        maxLength={props.maxLength}
                        name={props.name}
                        type={props.type}
                        autoComplete={props.autocomplete}
                    />
                )}
                {props.mask && (
                    <InputMask
                        className={classNames({
                            'form-control': true,
                            [styles.input]: props.disabled,
                        })}
                        style={inputMaskStyle}
                        ref={ref}
                        value={props.value}
                        disabled={props.disabled}
                        placeholder={
                            props.placeholder || _getPlaceHolder(props.mask)
                        }
                        onChange={e =>
                            props.saveUnmasked
                                ? props.onChange(_unMask(e))
                                : props.onChange(e)
                        }
                        onKeyPress={props.onKeyPress}
                        onBlur={props.onBlur}
                        mask={_getMask(props.mask)}
                        name={props.name}
                    />
                )}
                {props.inputGroupAfter}
            </InputGroupConditionalWrapper>
            {props.validation.state && (
                <FormControl.Feedback>
                    <FormValidationDisplay
                        validation={props.validation}
                        style={{ ...props.style, zIndex: 0 }}
                    />
                </FormControl.Feedback>
            )}
        </FormGroup>
    );
});

FormTextInput.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    bsSize: PropTypes.oneOf(['sm', 'small', 'lg', 'large']),
    required: PropTypes.bool,
    optional: PropTypes.bool,
    validation: PropTypes.shape({
        state: PropTypes.oneOf(['error', 'warning', 'success']),
        message: PropTypes.string,
    }),
    controlId: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    inputGroupBefore: PropTypes.element,
    inputGroupAfter: PropTypes.element,
    maxLength: PropTypes.number,
    mask: PropTypes.oneOf(['phone', 'date']),
    saveUnmasked: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    autocomplete: PropTypes.string,
};

FormTextInput.defaultProps = {
    style: {},
    className: '',
    validation: {},
    saveUnmasked: false,
    onChange: e => e,
};

export default FormTextInput;

function InputGroupConditionalWrapper(props) {
    if (props.inputGroupBefore || props.inputGroupAfter) {
        return (
            <InputGroup style={{ width: '100%' }}>{props.children}</InputGroup>
        );
    }
    return <span>{props.children}</span>;
}

function _getPlaceHolder(maskName) {
    switch (maskName) {
        case 'phone': {
            return '(___) ___-____';
        }
        case 'date': {
            return '__/__';
        }
        default: {
            return undefined;
        }
    }
}

function _getMask(maskName) {
    switch (maskName) {
        case 'phone': {
            return '(999) 999-9999';
        }
        case 'date': {
            return '99/99';
        }
        default: {
            return undefined;
        }
    }
}

function _unMask(e) {
    return {
        target: {
            value: e.target.value.replace(/\D/g, ''),
        },
    };
}

const inputMaskStyle = {
    display: 'block',
    width: '100%',
    height: '34px',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '1.42857',
    color: '#555555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
};
