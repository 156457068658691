/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import map from 'lodash/map';
import remove from 'lodash/remove';
import * as ApiService from './ApiService';

export default {
    timezones,
    country,
    currency,
    states,
    usStates,
    account: {
        opsAccounts,
        preferenceTypes: accountPreferenceTypes,
        roles: accountMotiliRoles,
        roleTypes: accountRoleTypes,
        departmentTypes: accountDepartmentTypes,
        departmentAssignmentTypes: accountDepartmentAssignmentTypes,
        types: accountTypes,
        statuses: accountStatuses,
    },
    client: {
        clients,
        statuses: clientStatuses,
        settings: clientSettings,
        freightTypes,
        integrationTypes: clientIntegrationTypes,
        statusChangedNotificationConfig: clientStatusChangedNotificationConfig,
        businessChannels: clientBusinessChannels,
        orderIssues: clientOrderIssues,
    },
    asset: {
        assetStatuses,
        photoTypes: assetPhotoTypes,
        retirementReasons: assetRetirementReasons,
        assetConfigurationCategories,
        untaggableReasons: assetUntaggableReasons,
    },
    contractor: {
        statuses: contractorStatuses,
        qualificationTypes: contractorQualificationTypes,
        member: {
            notifications: contractorNotifications,
            titles: contractorTeamMemberTitles,
            fileTypes: contractorTeamMemberFileTypes,
        },
        serviceTypes: contractorServiceTypes,
        credentials: contractorCredentials,
        certifications: contractorCertifications,
        clients: contractorClients,
        blocklistReasons: contractorBlocklistReasons,
        fileTypes: contractorFileTypes,
    },
    document: {
        types: documentTypes,
        tradeTypes: documentTradeTypes,
        photoTypes: documentPhotoTypes,
        priorityTypes: documentPriorityTypes,
        contactTypes: documentContactTypes,
        fulfillmentTypes: documentFulfillmentTypes,
        attachmentTypes: documentAttachmentTypes,
        assetCategories: documentAssetCategories,
        workOrder: {
            statuses: workOrderStatuses,
            types: workOrderTypes,
            pdfTypes: workOrderPdfTypes,
            issues: workOrderIssues,
            issueRejectedTypes: workOrderIssueRejectedTypes,
            contactPreferenceTypes: workOrderContactPreferenceTypes,
            requirementApprovalReasons: workOrderRequirementApprovalReasons,
        },
        quote: {
            statuses: quoteStatuses,
            types: quoteTypes,
            reasons: quoteReasons,
            reopenReasons: quoteReopenReasons,
            pdfTypes: quotePdfTypes,
            issues: quoteIssues,
        },
        order: {
            statuses: orderStatuses,
            types: orderTypes,
            fulfillmentMethods: orderFulfillmentMethods,
            contactTypes: orderContactTypes,
            notificationTypes,
            logisticsTypes,
            returnStatuses: orderReturnStatuses,
            returnReasons: orderReturnReasons,
            returnCancelReasons: orderReturnCancelReasons,
            issues: orderIssues,
        },
        draft: {
            statuses: draftStatuses,
        },
    },
    product: {
        categories: productCategories,
        attributes: productAttributes,
        pricingTypes: productPricingTypes,
        unitOfMeasures,
    },
    managedProduct: {
        categories: managedProductCategories,
        attributes: managedProductAttributes,
    },
    project: {
        projects,
        contactTypes: projectContactTypes,
        statuses: projectStatuses,
        types: projectTypes,
        scopeTypes: projectScopeTypes,
    },
    property: {
        properties,
        propertyTypes,
        propertyStatuses,
    },
    financial: {
        terms,
        billingStatuses,
        paymentStatuses,
        statuses: financialStatuses,
        invoiceLineOfBusinesses: financialInvoiceLineOfBusinesses,
        salesOrderLineOfBusinesses: financialSalesOrderLineOfBusinesses,
        syncQueueStatuses,
        syncQueueModels,
        syncQueueResolveReasons,
    },
    ticket: {
        providers: ticketProviders,
    },
    vendor: {
        allVendor,
        vendors,
        manufacturers,
        statuses: vendorStatuses,
        integrationTypes,
        distributionCenter: {
            dcConfigurations,
        },
    },
    template: {
        types: templateTypes,
    },
};

function timezones() {
    const query = {
        filter: {
            where: {
                or: [{ country: 'US' }, { country: 'CA' }],
            },
            order: ['display ASC'],
        },
    };
    return ApiService.get(true, 'TimeZones', query, false);
}

function country() {
    return ApiService.get(true, 'Countries', {}, false);
}

function currency() {
    return ApiService.get(true, 'Currencies', {}, false);
}

function states() {
    return ApiService.get(true, 'States', {}, false);
}

function usStates() {
    const query = {
        filter: {
            fields: ['id', 'display', 'counties'],
            order: ['display ASC'],
        },
    };
    return ApiService.get(true, 'UsStates', query, false);
}

/**
 * gets list of motili operations accounts
 * caution - motili_* only
 * @returns {Promise<Object>}
 */
function opsAccounts() {
    return ApiService.get(true, 'accounts', {
        filter: {
            where: {
                active: true,
                userType: 'motili',
            },
            order: ['firstName ASC', 'lastName ASC'],
            fields: [
                'id',
                'firstName',
                'lastName',
                'fullName',
                'department',
                'email',
            ],
            include: ['roles'],
        },
    });
}

function accountPreferenceTypes() {
    return ApiService.get(
        true,
        'accounts/preference-types',
        undefined,
        false
    ).then(preferenceTypes => sortBy(preferenceTypes, ['display']));
}

function accountMotiliRoles() {
    const query = {
        filter: {
            fields: ['name', 'description'],
        },
    };
    return ApiService.get(true, 'Roles', query, false).then(roles =>
        roles.map(r => ({
            id: r.name,
            name: r.name,
            display: r.description,
            description: r.description,
        }))
    );
}

function accountRoleTypes() {
    return ApiService.get(true, 'Accounts/roleTypes', false);
}

function accountStatuses() {
    return ApiService.get(true, 'Accounts/statuses', undefined, false);
}

function assetStatuses() {
    return ApiService.get(true, 'Assets/statuses', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function assetPhotoTypes() {
    return ApiService.get(true, 'Assets/photoTypes', undefined, false).then(
        photoTypes => sortBy(photoTypes, ['display'])
    );
}

function assetRetirementReasons() {
    return ApiService.get(true, 'Assets/retirementReasons', undefined, false);
}

function assetConfigurationCategories() {
    return ApiService.get(
        true,
        'AssetConfigurations/categories',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function assetUntaggableReasons() {
    return ApiService.get(true, 'Assets/untaggableReasons', undefined, false);
}

function documentTypes() {
    return ApiService.get(true, 'Documents/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function documentTradeTypes() {
    return ApiService.get(true, 'WorkOrders/tradeTypes', undefined, false)
        .then(tradeTypes => sortBy(tradeTypes, ['display']))
        .then(tradeTypes =>
            tradeTypes.map(tradeType => ({
                ...tradeType,
                tradeSubTypes: sortBy(tradeType.tradeSubTypes, ['display']),
            }))
        );
}

function documentPhotoTypes() {
    return ApiService.get(true, 'WorkOrders/photoTypes', undefined, false).then(
        photoTypes => sortBy(photoTypes, ['display'])
    );
}

function documentAssetCategories() {
    return ApiService.get(
        true,
        'AssetConfigurations/categories',
        undefined,
        false
    ).then(categories => {
        return sortBy(categories, ['display']);
    });
}

function documentPriorityTypes() {
    return ApiService.get(true, 'WorkOrders/priorities', undefined, false).then(
        priorityTypes => sortBy(priorityTypes, ['display'])
    );
}

function documentContactTypes() {
    return ApiService.get(
        true,
        'WorkOrders/contactTypes',
        undefined,
        false
    ).then(contactTypes => sortBy(contactTypes, ['display']));
}

function documentFulfillmentTypes() {
    return ApiService.get(
        true,
        'Orders/fulfillmentTypes',
        undefined,
        false
    ).then(fulfillmentTypes => sortBy(fulfillmentTypes, ['display']));
}

function documentAttachmentTypes() {
    return ApiService.get(
        true,
        'Folders/attachmentTypes',
        undefined,
        false
    ).then(attachmentTypes => sortBy(attachmentTypes, ['display']));
}

function workOrderStatuses() {
    return ApiService.get(true, 'WorkOrders/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function workOrderTypes() {
    return ApiService.get(true, 'WorkOrders/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function workOrderPdfTypes() {
    return ApiService.get(true, 'WorkOrders/pdfTypes', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function workOrderContactPreferenceTypes() {
    return ApiService.get(
        true,
        'WorkOrders/workOrderContactPreferenceTypes',
        undefined,
        false
    );
}

function workOrderRequirementApprovalReasons() {
    return ApiService.get(
        true,
        'WorkOrders/photoRequirementApprovalReasons',
        undefined,
        false
    );
}

function quoteStatuses() {
    return ApiService.get(true, 'Quotes/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function quoteTypes() {
    return ApiService.get(true, 'Quotes/types', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

function quoteReasons() {
    return ApiService.get(true, 'Quotes/reasons', undefined, false);
}

function quoteReopenReasons() {
    return ApiService.get(true, 'Quotes/reopenReasons', undefined, false);
}

function quotePdfTypes() {
    return ApiService.get(true, 'Quotes/quotePdfTypes', undefined, false).then(
        pdfTypes => sortBy(pdfTypes, ['display'])
    );
}

function orderStatuses() {
    return ApiService.get(true, 'Orders/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function orderIssues() {
    return ApiService.get(true, 'Orders/issues', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function workOrderIssues() {
    return ApiService.get(true, 'WorkOrders/issues', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function quoteIssues() {
    return ApiService.get(true, 'Quotes/issues', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function workOrderIssueRejectedTypes() {
    return ApiService.get(
        true,
        'WorkOrders/issueRejectedTypes',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function orderTypes() {
    return ApiService.get(true, 'Orders/types', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

function orderFulfillmentMethods() {
    return ApiService.get(
        true,
        'Orders/fulfillmentMethods',
        undefined,
        false
    ).then(fulfillmentMethods => sortBy(fulfillmentMethods, ['display']));
}

function orderContactTypes() {
    return ApiService.get(true, 'Orders/contactTypes', undefined, false).then(
        contactTypes => sortBy(contactTypes, ['display'])
    );
}

function draftStatuses() {
    return ApiService.get(true, 'Drafts/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function notificationTypes() {
    return ApiService.get(
        true,
        'Orders/notificationTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function logisticsTypes() {
    return ApiService.get(true, 'Orders/logisticsTypes', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

async function orderReturnStatuses() {
    const statuses = await ApiService.get(
        true,
        'Orders/orderReturnStatuses',
        undefined,
        false
    );
    return buildListWithOther(statuses, ['OTHER'], ['display']);
}

async function orderReturnReasons() {
    const reasons = await ApiService.get(
        true,
        'Orders/orderReturnReasons',
        undefined,
        false
    );
    return buildListWithOther(reasons, ['OTHER'], ['display']);
}

async function orderReturnCancelReasons() {
    const reasons = await ApiService.get(
        true,
        'Orders/orderReturnCancelReasons',
        undefined,
        false
    );
    return buildListWithOther(reasons, ['OTHER'], ['display']);
}

function accountDepartmentTypes() {
    return ApiService.get(
        true,
        'Accounts/departmentTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function accountDepartmentAssignmentTypes() {
    return ApiService.get(
        true,
        'Accounts/departmentAssignmentTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function accountTypes() {
    return ApiService.get(true, 'Accounts/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function terms() {
    return ApiService.get(true, 'Terms/terms', undefined, false)
        .then(types => sortBy(types, ['display']))
        .then(types => {
            const _types = [...types];
            const sortByDateTypes = remove(_types, type =>
                type.display.startsWith('Net ')
            );
            return [..._types, ...sortBy(sortByDateTypes, ['days'])];
        });
}

function billingStatuses() {
    return ApiService.get(true, 'Billings/statuses', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function paymentStatuses() {
    return ApiService.get(true, 'Payments/statuses', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

async function financialStatuses() {
    const _billingStatuses = await ApiService.get(
        true,
        'Billings/statuses',
        undefined,
        false
    );
    const _paymentStatuses = await ApiService.get(
        true,
        'Payments/statuses',
        undefined,
        false
    );
    return sortBy(unionBy(_billingStatuses, _paymentStatuses, 'display'), [
        'display',
    ]);
}

async function financialInvoiceLineOfBusinesses() {
    const _billingInvoiceLineOfBusinesses = await ApiService.get(
        true,
        'Billings/invoiceLineOfBusinesses',
        undefined,
        false
    );
    const _paymentInvoiceLineOfBusinesses = await ApiService.get(
        true,
        'Payments/invoiceLineOfBusinesses',
        undefined,
        false
    );
    return {
        billing: _billingInvoiceLineOfBusinesses
            ? _billingInvoiceLineOfBusinesses.sort()
            : [],
        payment: _paymentInvoiceLineOfBusinesses
            ? _paymentInvoiceLineOfBusinesses.sort()
            : [],
    };
}
async function financialSalesOrderLineOfBusinesses() {
    const _billingSalesOrderLineOfBusinesses = await ApiService.get(
        true,
        'Billings/salesOrderLineOfBusinesses',
        undefined,
        false
    );
    const _mergeLOB = ['Sales Order - Integrated', 'Sales Order - eCommerce'];

    return {
        billing: _billingSalesOrderLineOfBusinesses
            ? _billingSalesOrderLineOfBusinesses.sort()
            : [],
        billingMerge: _billingSalesOrderLineOfBusinesses
            ? filter(_billingSalesOrderLineOfBusinesses, it =>
                  _mergeLOB.includes(it)
              ).sort()
            : [],
    };
}

/**
 * gets list of clients for client drop down lists
 * caution - motili_* only
 * @returns {Promise<Object>}
 */
function clients() {
    return ApiService.get(true, 'Clients', {
        filter: {
            where: {
                statusId: {
                    inq: ['APPROVED', 'ENABLED'],
                },
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'statusId',
                'website',
                'addressId',
                'operationInCountry',
                'currency',
            ],
            include: [
                {
                    relation: 'address',
                    scope: {
                        fields: [
                            'stdDeliveryLine',
                            'stdLastLine',
                            'standardized',
                            'rawAddress',
                        ],
                    },
                },
                {
                    relation: 'settings',
                    scope: {
                        fields: ['settingTypeId', 'setting'],
                        where: {
                            settingTypeId: 'LINE_OF_BUSINESS',
                        },
                    },
                },
            ],
        },
    });
}

function clientStatuses() {
    return ApiService.get(true, 'Clients/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function clientSettings() {
    return ApiService.get(true, 'Clients/settingTypes', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function freightTypes() {
    return ApiService.get(true, 'Clients/freightTypes', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function clientIntegrationTypes() {
    return ApiService.get(
        true,
        'Clients/integrationTypes',
        undefined,
        false
    ).then(integrations => sortBy(integrations, ['display']));
}

function clientStatusChangedNotificationConfig() {
    return ApiService.get(
        true,
        'Clients/statusChangedNotificationConfig',
        undefined,
        false
    );
}

function clientBusinessChannels() {
    return ApiService.get(
        true,
        'Clients/businessChannels',
        undefined,
        false
    ).then(channels => sortBy(channels, ['display']));
}

function clientOrderIssues() {
    return ApiService.get(true, 'Clients/orderIssues', undefined, false).then(
        issues => sortBy(issues, ['display'])
    );
}

function contractorQualificationTypes() {
    return ApiService.get(
        true,
        'Contractors/qualificationTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function contractorFileTypes() {
    return ApiService.get(true, 'Contractors/fileTypes', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function contractorNotifications() {
    return ApiService.get(
        true,
        'Contractors/teamMemberNotifications',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function contractorTeamMemberTitles() {
    return ApiService.get(
        true,
        'ContractorTeams/titles',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function contractorTeamMemberFileTypes() {
    return ApiService.get(
        true,
        'ContractorTeams/fileTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function contractorStatuses() {
    return ApiService.get(true, 'Contractors/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

async function contractorServiceTypes() {
    const serviceTypes = await ApiService.get(
        true,
        'Contractors/serviceTypes',
        undefined,
        false
    );
    // Sorts serviceTypes alphabetically by keys, sortBy function loses keys so invalid here
    return Object.keys(serviceTypes)
        .sort()
        .reduce((obj, key) => {
            obj[key] = serviceTypes[key];
            return obj;
        }, {});
}
function contractorCredentials() {
    return ApiService.get(true, 'Contractors/credentials', undefined, false);
}
function contractorCertifications() {
    return ApiService.get(true, 'Contractors/certifications', undefined, false);
}

function contractorClients(contractorId) {
    if (!contractorId) {
        return Promise.resolve([]);
    }
    return ApiService.get(
        true,
        `Contractors/${contractorId}/clients`,
        undefined,
        false
    );
}

function contractorBlocklistReasons() {
    return ApiService.get(
        true,
        'Contractors/blocklistReasons',
        undefined,
        false
    );
}

export function productCategories() {
    return ApiService.get(true, 'products/categories')
        .then(categories => sortBy(categories, ['display']))
        .then(categories =>
            categories.map(category => ({
                ...category,
                subCategories: sortBy(category.subCategories, ['display']),
            }))
        );
}

export function managedProductCategories() {
    return ApiService.get(true, 'managedProducts/categories')
        .then(categories => sortBy(categories, ['display']))
        .then(categories =>
            categories.map(category => ({
                ...category,
                subCategories: sortBy(category.subCategories, ['display']),
            }))
        );
}

function properties() {
    return ApiService.get(true, 'Properties', {
        filter: {
            where: {
                active: true,
            },
            order: ['rawAddress ASC'],
            fields: ['id', 'name', 'rawAddress'],
        },
    });
}

function propertyTypes() {
    return ApiService.get(true, 'Properties/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

function propertyStatuses() {
    return ApiService.get(true, 'Properties/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function productAttributes() {
    return ApiService.get(true, 'products/attributeTypes')
        .then(attributes => sortBy(attributes, ['display']))
        .then(attributes =>
            attributes.map(attribute => ({
                ...attribute,
                subTypes: sortBy(attribute.subTypes, ['display']),
            }))
        );
}

export function managedProductAttributes() {
    return ApiService.get(true, 'managedProducts/attributeTypes')
        .then(attributes => sortBy(attributes, ['display']))
        .then(attributes =>
            attributes.map(attribute => ({
                ...attribute,
                subTypes: sortBy(attribute.subTypes, ['display']),
            }))
        );
}

export function productPricingTypes() {
    return ApiService.get(true, 'managedpricing/types').then(types =>
        sortBy(types, 'display')
    );
}

export function unitOfMeasures() {
    return ApiService.get(true, 'managedProducts/unitOfMeasures').then(res =>
        sortBy(res, ['display'])
    );
}

export function vendors() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            where: {
                statusId: 'APPROVED',
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
                'operationInCountry',
                'currency',
            ],
        },
    });
}

export function allVendor() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

async function projects() {
    const _project = await ApiService.get(true, 'Projects', undefined, false);
    return sortBy(_project, ['name']);
}
function projectContactTypes() {
    return ApiService.get(true, 'Projects/contactTypes', undefined, false);
}

function projectStatuses() {
    return ApiService.get(true, 'Projects/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

function projectTypes() {
    return ApiService.get(true, 'Projects/types', undefined, false).then(
        types => types
    );
}

function projectScopeTypes() {
    return ApiService.get(true, 'Projects/scopeTypes', undefined, false).then(
        types => types
    );
}

export function manufacturers() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            where: {
                active: true,
                typeId: 'MANUFACTURER',
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

export function ticketProviders() {
    return ApiService.get(true, 'Tickets/providers', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function dcConfigurations() {
    return ApiService.get(
        true,
        'ManagedVendors/distribution/types',
        undefined,
        false
    ).then(configurations => configurations);
}

export function integrationTypes() {
    return ApiService.get(
        true,
        'ManagedVendors/integration/types',
        undefined,
        false
    ).then(_integrationTypes => _integrationTypes);
}

export function vendorStatuses() {
    return ApiService.get(
        true,
        'ManagedVendors/statuses',
        undefined,
        false
    ).then(statuses => statuses);
}

export function templateTypes() {
    return ApiService.get(true, 'Templates/types', undefined, false);
}

/**
 * combines trade and sub types into single array
 * @param {Object} tradeTypes
 * @returns {Array}
 */
export function buildTradeAndSubTypes(tradeTypes) {
    return tradeTypes.reduce((acc, next) => {
        const tradeAndSubTypesForTradeType = map(
            next.tradeSubTypes,
            subType => ({
                id: `${next.id}-${subType.id}`,
                display: `${next.display} - ${subType.display}`,
                tradeTypeId: next.id,
                tradeSubTypeId: subType.id,
            })
        );
        return acc.concat(tradeAndSubTypesForTradeType);
    }, []);
}

/**
 * Build list and move other(given otherIds) to last index.
 *
 * @param list array
 * @param otherIds array
 * @param sortKeys array
 * @return array
 */
function buildListWithOther(list, otherIds, sortKeys) {
    if (otherIds && otherIds.length > 0) {
        let others = list.filter(s => {
            return otherIds.find(id => {
                return id === s.id;
            });
        });
        if (others) {
            others = sortBy(others, sortKeys);
            let returnList = list.filter(s => {
                return !otherIds.find(id => {
                    return id === s.id;
                });
            });
            returnList = sortBy(returnList, sortKeys);
            returnList = returnList.concat(others);
            return returnList;
        }
        return sortBy(list, sortKeys);
    }
    return sortBy(list, sortKeys);
}

function syncQueueStatuses() {
    return ApiService.get(true, 'financeSyncQueues/statuses', undefined, false)
        .then(types => sortBy(types, ['display']))
        .catch(() => []);
}

function syncQueueModels() {
    return ApiService.get(true, 'financeSyncQueues/models', undefined, false)
        .then(types => sortBy(types, ['display']))
        .catch(() => []);
}

function syncQueueResolveReasons() {
    return ApiService.get(
        true,
        'financeSyncQueues/resolveReasons',
        undefined,
        false
    )
        .then(types => sortBy(types, ['display']))
        .catch(() => []);
}
