/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import {
    configureStore,
    getDefaultMiddleware,
    combineReducers,
} from '@reduxjs/toolkit';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import contractor from 'admin/stores/redux/ducks/AdminContractorRegistration';
import recentlyViewed from 'admin/stores/redux/AdminRecentlyViewedStore';
import truck from 'admin/stores/redux/ducks/Truck';
import workOrder from 'admin/stores/redux/ducks/AdminWorkOrderCreation';
import recentlyViewedJobs from 'contractor/stores/ContractorRecentlyViewedJobsStore';
import adminFinanceInvoicesListFilter, {
    actions as adminFinanceInvoicesListFilterActions,
} from 'admin/stores/redux/AdminFinanceInvoicesListFilter';
import adminFinanceInvoiceCreateFilter from 'admin/stores/redux/AdminFinanceInvoiceCreateFilter';
import adminFinanceSyncTaskListFilter from 'admin/stores/redux/AdminFinanceSyncTaskListFilter';
import clientOrderTableSettingsReducer from 'client/stores/slices/ClientOrderTableSettingsSlice';

import metaStore from 'common/stores/redux/MetaStore';
import requirements from 'common/stores/RequirementsSpecsStore';

import businessChannel from '../../../admin/stores/redux/AdminBusinessChannelStore';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [
        'contractor',
        'workOrder',
        'truck',
        'requirements',
        'recentlyViewedJobs',
    ],
};

const rootReducer = combineReducers({
    contractor,
    recentlyViewed,
    truck,
    workOrder,
    metaStore,
    requirements,
    businessChannel,
    recentlyViewedJobs,
    adminFinanceInvoicesListFilter,
    adminFinanceInvoiceCreateFilter,
    adminFinanceSyncTaskListFilter,
    clientOrderTableSettingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const initStoreAfterLogin = () => {
    store.dispatch(adminFinanceInvoicesListFilterActions.init());
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
