/**
 * Copyright Motili Inc., 2023 All Rights Reserved
 */

import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import Raven from 'raven-js';
import { ServiceWorkerProvider } from 'useServiceWorker';
import { pdfjs } from 'react-pdf';

import alt from './alt';
import Config from './Config';
import * as AuthService from './common/services/AuthService';
import App from './App';
import './serviceWorkerErrorHandler';
import { UserTokenProvider } from './useUserToken';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

(function initializeReactGA() {
    ReactGA.initialize(Config.googleAnalyticsID);
})();

Raven.config(Config.sentry.dsn, {
    release: Config.release,
    environment: Config.environment,
    shouldSendCallback() {
        return !(
            Config.environment === 'local' ||
            Config.environment === 'dashboardlocal' ||
            Config.environment === 'dev' ||
            Config.environment === 'dev2' ||
            Config.environment === 'dashboarddevintg'
        );
    },
}).install();

if (AuthService.isLoggedIn()) {
    Raven.setUserContext({
        id: AuthService.getAccount().id,
        email: AuthService.getAccount().email,
    });

    if (AuthService.isMotiliAccount()) {
        Raven.setExtraContext({
            userContext: 'ops',
        });
    }

    if (AuthService.isClientAccount()) {
        Raven.setExtraContext({
            userContext: 'cli',
        });
    }

    if (AuthService.isContractorAccount()) {
        Raven.setExtraContext({
            userContext: 'con',
        });
    }

    alt.bootstrap(
        JSON.stringify({
            AuthStore: {
                authenticated: true,
                initialized: true,
                isMotiliAccount: AuthService.isMotiliAccount(),
                isClientAccount: AuthService.isClientAccount(),
                isContractorAccount: AuthService.isContractorAccount(),
                userName: AuthService.getAccountUserName(),
                fullName: AuthService.getAccountFullName(),
                token: AuthService.getToken(),
                clientId: AuthService.getClientId(),
                contractorId: AuthService.getContractorId(),
                client: AuthService.getClient(),
                contractor: AuthService.getContractor(),
                latestAcknowledgment: AuthService.latestAcknowledgment(),
            },
        })
    );
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <ServiceWorkerProvider>
        <UserTokenProvider>
            <App />
        </UserTokenProvider>
    </ServiceWorkerProvider>
);
