/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Contractor,
    Job,
    Project,
    Property,
    WorkOrderCreation,
} from 'common/types';

interface WorkOrderCreationState {
    contractors: Contractor[];
    projects: Project[];
    property: Partial<Property> | null;
    recentJobs: Job[] | null;
    workOrder: WorkOrderCreation;
    pageNumber: number;
}

const initialState: WorkOrderCreationState = {
    contractors: [],
    projects: [],
    property: null,
    recentJobs: null,
    workOrder: {
        // MANDATORY FOR CREATION
        documentTypeId: '',
        workOrderTypeId: '',
        priorityId: '',
        tradeTypeId: '',
        tradeSubTypeId: '',
        propertyId: '',
        opsJobManager: 0,
        // OPTIONAL
        coOwner: undefined,
        opsContractorManager: '',
        jobId: '',
        documentOrigination: null,
        occupied: false,
        cleanAndCheck: false,
        instructions: {
            gateCode: '',
            lockboxCode: '',
            special: '',
        },
        poNumber: '',
        contacts: [
            {
                email: '',
                mobile: '',
                name: '',
                phone: '',
                typeId: '',
                contactPreferenceId: '',
                notes: '',
                validMobile: null,
                validPhone: null,
            },
        ],
        schedule: null,
        extReferenceNumber1: '',
        extReferenceNumber2: '',
        extPoNumber: '',
        clientId: '',
        contractorId: '',
        contractorTeamMemberId: '',
        fulfillmentDetails: '',
        agentBilledId: undefined,
        checklists: [],
        photoRequirements: [],
        originator: {
            accountId: '',
            type: '',
            firstName: '',
            lastName: '',
        },
        clipLevel: '',
    },
    pageNumber: 1,
};

const workOrderCreationSlice = createSlice({
    name: 'workOrderCreation',
    initialState,
    reducers: {
        setStoreWorkOrder(state, action: PayloadAction<WorkOrderCreation>) {
            state.workOrder = { ...state.workOrder, ...action.payload };
        },
        setStoreContractors(state, action: PayloadAction<Contractor[]>) {
            state.contractors = action.payload;
        },
        setStoreProjects(state, action: PayloadAction<Project[]>) {
            state.projects = action.payload;
        },
        setStoreProperty(
            state,
            action: PayloadAction<Partial<Property> | null>
        ) {
            state.property = action.payload;
        },
        setStoreRecentJobs(state, action: PayloadAction<Job[] | null>) {
            state.recentJobs = action.payload;
        },
        setStorePageNumber(state, action: PayloadAction<number>) {
            state.pageNumber = action.payload;
        },
        resetWorkOrderStore() {
            return initialState;
        },
    },
});

export const {
    setStoreContractors,
    setStoreProjects,
    setStoreProperty,
    setStoreRecentJobs,
    setStoreWorkOrder,
    setStorePageNumber,
    resetWorkOrderStore,
} = workOrderCreationSlice.actions;

export default workOrderCreationSlice.reducer;
