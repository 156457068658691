/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import MetaService from '../../common/services/MetaService';
import * as AuthService from '../../common/services/AuthService';

const init = async () => {
    const requests = [
        MetaService.timezones(),
        MetaService.country(),
        MetaService.currency(),
        MetaService.states(),
        MetaService.usStates(),
        MetaService.financial.statuses(),
        MetaService.account.preferenceTypes(),
        MetaService.account.roles(),
        MetaService.account.departmentTypes(),
        MetaService.account.departmentAssignmentTypes(),
        MetaService.account.types(),
        MetaService.account.roleTypes(),
        MetaService.asset.assetStatuses(),
        MetaService.asset.photoTypes(),
        MetaService.asset.retirementReasons(),
        MetaService.asset.assetConfigurationCategories(),
        MetaService.asset.untaggableReasons(),
        MetaService.contractor.statuses(),
        MetaService.contractor.member.notifications(),
        MetaService.contractor.member.titles(),
        MetaService.contractor.member.fileTypes(),
        MetaService.contractor.fileTypes(),
        MetaService.contractor.serviceTypes(),
        MetaService.contractor.certifications(),
        MetaService.contractor.credentials(),
        MetaService.contractor.clients(AuthService.getContractorId()),
        MetaService.document.types(),
        MetaService.document.tradeTypes(),
        MetaService.document.photoTypes(),
        MetaService.document.priorityTypes(),
        MetaService.document.contactTypes(),
        MetaService.document.fulfillmentTypes(),
        MetaService.document.attachmentTypes(),
        MetaService.document.workOrder.statuses(),
        MetaService.document.workOrder.types(),
        MetaService.document.workOrder.issues(),
        MetaService.document.workOrder.requirementApprovalReasons(),
        MetaService.document.workOrder.contactPreferenceTypes(),
        MetaService.document.quote.statuses(),
        MetaService.document.quote.types(),
        MetaService.document.quote.reasons(),
        MetaService.document.order.statuses(),
        MetaService.document.order.contactTypes(),
        MetaService.document.order.notificationTypes(),
        MetaService.document.order.logisticsTypes(),
        MetaService.document.order.returnStatuses(),
        MetaService.document.order.returnReasons(),
        MetaService.product.categories(),
        MetaService.product.attributes(),
        MetaService.property.propertyTypes(),
    ];

    const [
        timezones,
        country,
        currency,
        states,
        usStates,
        financialStatuses,
        accountPreferenceTypes,
        accountRoles,
        accountDepartmentTypes,
        accountDepartmentAssignmentTypes,
        accountTypes,
        accountRolesTypes,
        assetStatues,
        assetPhotoTypes,
        assetRetirementReasons,
        assetConfigurationCategories,
        untaggableReasons,
        contractorStatuses,
        contractorMemberNotifications,
        contractorTeamMemberTitles,
        contractorTeamMemberFileTypes,
        contractorFileTypes,
        contractorServiceTypes,
        contractorCertifications,
        contractorCredentials,
        contractorClients,
        documentTypes,
        documentTradeTypes,
        documentPhotoTypes,
        documentPriorityTypes,
        documentContactTypes,
        documentfulfillmentTypes,
        documentAttachmentTypes,
        workOrderStatuses,
        workOrderTypes,
        workOrderIssues,
        workOrderRequirementApprovalReasons,
        workOrderContactPreferenceTypes,
        quoteStatuses,
        quoteTypes,
        quoteReasons,
        orderStatuses,
        orderContactTypes,
        orderNotificationTypes,
        orderLogisticsTypes,
        orderReturnStatuses,
        orderReturnReasons,
        productCategories,
        productAttributes,
        propertyTypes,
    ] = await Promise.all(requests);

    return {
        timezones,
        country,
        currency,
        states,
        usStates,
        financial: {
            terms: [],
            statuses: financialStatuses,
        },
        account: {
            opsAccounts: [],
            preferenceTypes: accountPreferenceTypes,
            roles: accountRoles,
            departmentTypes: accountDepartmentTypes,
            departmentAssignmentTypes: accountDepartmentAssignmentTypes,
            types: accountTypes,
            roleTypes: accountRolesTypes,
        },
        asset: {
            assetStatuses: assetStatues,
            photoTypes: assetPhotoTypes,
            retirementReasons: assetRetirementReasons,
            assetConfigurationCategories,
            untaggableReasons,
        },
        client: {
            statuses: [],
            settings: [],
            freightTypes: [],
        },
        contractor: {
            statuses: contractorStatuses,
            qualificationTypes: [],
            member: {
                notifications: contractorMemberNotifications,
                titles: contractorTeamMemberTitles,
                fileTypes: contractorTeamMemberFileTypes,
            },
            serviceTypes: contractorServiceTypes,
            certifications: contractorCertifications,
            credentials: contractorCredentials,
            clients: contractorClients,
            blocklistReasons: [],
            fileTypes: contractorFileTypes,
        },
        document: {
            types: documentTypes,
            tradeTypes: documentTradeTypes,
            photoTypes: documentPhotoTypes,
            priorityTypes: documentPriorityTypes,
            contactTypes: documentContactTypes,
            fulfillmentTypes: documentfulfillmentTypes,
            attachmentTypes: documentAttachmentTypes,
            workOrder: {
                statuses: workOrderStatuses,
                types: workOrderTypes,
                contactPreferenceTypes: workOrderContactPreferenceTypes,
                issues: workOrderIssues,
                requirementApprovalReasons: workOrderRequirementApprovalReasons,
            },
            quote: {
                statuses: quoteStatuses,
                types: quoteTypes,
                reasons: quoteReasons,
            },
            order: {
                statuses: orderStatuses,
                types: [],
                contactTypes: orderContactTypes,
                notificationTypes: orderNotificationTypes,
                logisticsTypes: orderLogisticsTypes,
                returnStatuses: orderReturnStatuses,
                returnReasons: orderReturnReasons,
                returnCancelReasons: [],
                issues: [],
            },
            draft: {
                statuses: [],
            },
        },
        product: {
            categories: productCategories,
            attributes: productAttributes,
            pricingTypes: [],
            unitOfMeasures: [],
        },
        project: {
            contactTypes: [],
            statuses: [],
            types: [],
            scopeTypes: [],
        },
        property: {
            types: propertyTypes,
            typesNew: [],
            statuses: [],
        },
        template: {
            types: [],
        },
        ticket: {
            providers: [],
        },
        vendor: {
            // allVendor: allVendor,
            // vendors: vendors,
            // manufacturers: manufacturers,
            statuses: [],
            integrationTypes: [],
            distributionCenter: {
                configurations: [],
            },
        },
    };
};

export default { init };
