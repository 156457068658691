/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import _ from 'lodash';

import { generateRandomUUID } from 'common/utils/Utils';

import {
    FulfillmentDetails,
    FulfillmentDistributionCenter,
    LineItem,
    Product,
    ProductWrapper,
    Truck,
} from 'common/types';

const initialState: { truck: Truck } = {
    truck: {
        initialized: true,
        products: [],
        hasPackagedProducts: false,
        lineItems: [],
        fulfillmentDetails: null,
        deliveryOptions: [],
        fulfillmentDistributionCenter: null,
    },
};

const truckSlice = createSlice({
    name: 'truck',
    initialState,
    reducers: {
        initTruck(state, action: PayloadAction<Partial<Truck>>) {
            state.truck = { ...state.truck, ...action.payload };
        },
        setTruck(state, action: PayloadAction<Truck>) {
            state.truck = action.payload;
        },
        updateTruckLineItems(state, action: PayloadAction<LineItem[]>) {
            state.truck.lineItems = action.payload || [];
        },
        updateTruckLineItemsCost(state, action: PayloadAction<any[]>) {
            const calculatedLineItems = action.payload;
            const lineItems = _.map(state.truck.lineItems, lineItem => {
                const update = _.find(
                    calculatedLineItems,
                    cLi => cLi.id === lineItem.id
                );
                if (update) {
                    return {
                        ...lineItem,
                        cost: update.cost,
                        price: update.price,
                        payout: update.payout,
                    };
                }
                return lineItem;
            });
            const products = _.map(state.truck.products, product => {
                const update = _.find(
                    calculatedLineItems,
                    cLi => cLi.uuid && cLi.uuid === product.product?.uuid
                );
                if (update) {
                    return {
                        ...product,
                        product: {
                            ...product.product,
                            cost: update.cost,
                            price: update.price,
                            payout: update.payout,
                        },
                    };
                }
                return product;
            });
            state.truck.lineItems = lineItems || [];
            state.truck.products = products || [];
        },
        removeTruckLineItem(state, action: PayloadAction<number>) {
            const _lineItems = state.truck.lineItems;
            _lineItems.splice(
                _.findIndex(state.truck.lineItems, { id: action.payload }),
                1
            );
            state.truck.lineItems = _lineItems;
        },
        enqueueTruck(state, action: PayloadAction<Array<any>>) {
            const args = action.payload;
            const doNotMerge = ['SUPPLIED', 'CUSTOM'];
            let _products;

            // if product is already in truck increment quantity
            if (
                state.truck.products.some(
                    (product: ProductWrapper) =>
                        product.product.id === args[0].id
                ) &&
                !doNotMerge.includes(args[0].productCategoryId)
            ) {
                _products = state.truck.products.map(
                    (product: ProductWrapper) =>
                        product.product.id === args[0].id
                            ? {
                                  ...product,
                                  supplied: {
                                      ...product.supplied,
                                      quantity:
                                          product.supplied.quantity +
                                          args[1].quantity,
                                  },
                              }
                            : product
                );
            } else {
                // else add it to products array
                _products = [
                    ...state.truck.products,
                    {
                        product: { ...args[0], uuid: generateRandomUUID() },
                        supplied: args[1],
                    },
                ];
            }

            state.truck.products = _products;
        },
        enqueueTruckMany(state, action: PayloadAction<ProductWrapper[]>) {
            const args = action.payload;
            let _products = state.truck.products;

            for (let i = 0; i < args.length; i++) {
                if (
                    state.truck.products.some(
                        (product: ProductWrapper) =>
                            product.product.id === args[i].product.id
                    )
                ) {
                    _products = _products.map((product: ProductWrapper) =>
                        product.product.id === args[i].product.id
                            ? {
                                  ...product,
                                  supplied: {
                                      ...product.supplied,
                                      quantity:
                                          product.supplied.quantity +
                                          args[i].supplied.quantity,
                                  },
                              }
                            : product
                    );
                } else {
                    _products.push({
                        product: {
                            ...args[i].product,
                            uuid: generateRandomUUID(),
                        },
                        supplied: args[i].supplied,
                    });
                }
            }

            if (state.truck.hasPackagedProducts) {
                const __products = _products.map((product: ProductWrapper) => {
                    if (product.product.productCategoryId === 'LABOR')
                        return zeroPrice(product);

                    return product;
                });

                state.truck.products = __products;
            }

            state.truck.products = _products;
        },
        enqueueTruckPackagedProduct(
            state,
            action: PayloadAction<ProductWrapper & Product>
        ) {
            const args = action.payload;
            // zero the price of any existing labor products
            const _products = state.truck.products.map(
                (product: ProductWrapper) => {
                    if (product.product.productCategoryId === 'LABOR')
                        return zeroPrice(product);

                    return product;
                }
            );

            // add package product
            _products.push({
                product: { ...args.product, uuid: generateRandomUUID() },
                supplied: args.supplied,
            });

            // add the package sub-products
            args.packagedProducts.forEach((product: ProductWrapper) => {
                _products.push({
                    product: {
                        ...zeroPrice(product).product,
                        uuid: generateRandomUUID(),
                    },
                    supplied: {
                        description: null,
                        quantity: 1,
                    },
                });
            });

            state.truck.products = _products;
            state.truck.hasPackagedProducts = true;
        },
        truckDequeue(state, action: PayloadAction<string>) {
            const uuid = action.payload;
            const _products = state.truck.products;
            const productIndex = _products.findIndex(
                (_product: ProductWrapper) => _product.product.uuid === uuid
            );
            _products.splice(productIndex, 1);

            if (
                !_products.find(
                    (product: ProductWrapper) =>
                        product.product.productCategoryId === 'PACKAGES'
                )
            ) {
                state.truck.hasPackagedProducts = false;
            }

            state.truck.products = _products;
        },
        updateTruckFulfillmentDetails(
            state,
            action: PayloadAction<{
                fulfillmentDetails: FulfillmentDetails | null;
                fulfillmentDistributionCenter: FulfillmentDistributionCenter | null;
            }>
        ) {
            const { fulfillmentDetails, fulfillmentDistributionCenter } =
                action.payload;
            state.truck.fulfillmentDetails = fulfillmentDetails;
            state.truck.fulfillmentDistributionCenter =
                fulfillmentDistributionCenter;
        },

        truckFulfillmentToNameOnChange(state, action: PayloadAction<string>) {
            const name = action.payload;
            const _fulfillmentDetails = {
                ...state.truck.fulfillmentDetails,
                fulfillmentTo: name,
            };
            state.truck.fulfillmentDetails = _fulfillmentDetails;
        },
        truckResidentialDeliveryOnChange(
            state,
            action: PayloadAction<boolean>
        ) {
            const _delivery = action.payload;
            const _fulfillmentDetails = {
                ...state.truck.fulfillmentDetails,
                residentialDelivery: _delivery,
            };
            state.truck.fulfillmentDetails = _fulfillmentDetails;
        },
        resetTruckProduct(state) {
            state.truck.products = [];
        },
        clearTruck(state) {
            state.truck.products = [];
            state.truck.lineItems = [];
            state.truck.deliveryOptions = [];
            state.truck.fulfillmentDetails = null;
            state.truck.fulfillmentDistributionCenter = null;
        },
        resetTruck(state) {
            state.truck = getInitialState();
        },
    },
});

function zeroPrice(product: ProductWrapper) {
    return {
        ...product,
        product: {
            ...product.product,
            adjustPrice: true,
            price: {
                ...product.product.price,
                price: 0,
                priceIncludingTax: 0,
                priceTax: 0,
            },
        },
    };
}

function getInitialState() {
    return {
        initialized: false,
        products: [],
        hasPackagedProducts: false,
        lineItems: [],
        deliveryOptions: [],
        fulfillmentDetails: null,
        fulfillmentDistributionCenter: null,
    };
}

export const {
    initTruck,
    setTruck,
    updateTruckLineItems,
    updateTruckLineItemsCost,
    removeTruckLineItem,
    enqueueTruck,
    enqueueTruckMany,
    enqueueTruckPackagedProduct,
    truckDequeue,
    updateTruckFulfillmentDetails,
    truckFulfillmentToNameOnChange,
    truckResidentialDeliveryOnChange,
    resetTruckProduct,
    clearTruck,
    resetTruck,
} = truckSlice.actions;

export default truckSlice.reducer;
