import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    columnVisibility: {
        tradeTypeId: false,
        priorityId: false,
        pickingUpAt: false,
        property: false,
    },
};

export const clientOrderTableSettingsSlice = createSlice({
    name: 'clientOrderTableSettings',
    initialState,
    reducers: {
        setColumnVisibility: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.columnVisibility = action.payload;
        },
    },
});

export const { setColumnVisibility } = clientOrderTableSettingsSlice.actions;

export default clientOrderTableSettingsSlice.reducer;
